import { HodlPalValues } from "./main";

function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length != b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

function containsObject(obj, list) {
  var i;
  if (list == null) return false;
  for (i = 0; i < list.length; i++) {
      if (arraysEqual(list[i], obj)) {
          return true;
      }
  }
  return false;
}

function getCookie(name) {
  var cookieValue = null;
  if (document.cookie && document.cookie != '') {
      var cookies = document.cookie.split(';');
      for (var i = 0; i < cookies.length; i++) {
          var cookie = jQuery.trim(cookies[i]);
          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, name.length + 1) == (name + '=')) {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
              break;
          }
      }
  }
  return cookieValue;
}

function ajax_request(url, data, success_function) {

  $.ajax({
    type: "POST",
    beforeSend: function(request) {
      request.setRequestHeader("X-CSRF-Token", getCookie('csrftoken'));
    },        
    url: url,
    dataType: 'json',
    data: data,
    success: success_function
  });

}

// this returns HTML for an upvoted button
function get_upvoted_button_html(parentPostID, topicPair) {
  return '<button class="btn btn-success vote-button" data-post-id="' + parentPostID + '" data-topic-id="' + topicPair[0] + '" data-upvoted="1"><span class="noselect">' + topicPair[1] + '</span></button> '
}

// this returns HTML for an empty (not upvoted) button
function get_empty_button_html(parentPostID, topicPair) {
  return '<button class="btn btn-outline-success vote-button" data-post-id="' + parentPostID + '" data-topic-id="' + topicPair[0] + '" data-upvoted="0"><span class="noselect">' + topicPair[1] + '</span></button> ';
}

// This appends one upvoted button to the set of buttons identified by the vote-buttons id
function append_voted_button(parentPostID, topicPair) {
  const voteButtons = $('#vote-buttons');
  var voteButtonsHtml = voteButtons.html();
  voteButtonsHtml += get_upvoted_button_html(parentPostID, topicPair);
  voteButtons.html(voteButtonsHtml);
}

// This overwrites the set of vote buttons with content pertaining to postTopics and postUpvotedTopics.
function overwrite_vote_buttons(parentPostID) {
  // Iterate through post topics
  const postTopics = HodlPalValues.postTopics[parentPostID];
  const postUpvotedTopics = HodlPalValues.postUpvotedTopics[parentPostID];
  var voteButtonsHtml = '';
  postTopics.forEach(function (topicPair) {
    if (containsObject(topicPair, postUpvotedTopics)) {
      voteButtonsHtml += get_upvoted_button_html(parentPostID, topicPair);
    } else {
      voteButtonsHtml += get_empty_button_html(parentPostID, topicPair);
    }

  })
  const voteButtons = $('#vote-buttons');
  voteButtons.html(voteButtonsHtml); 
}

function update_upvote_arrow(post_id)
{
  // see whether this post has any upvotes. if so, make it active. if not, inactive.
  const upvotes = HodlPalValues.postUpvotedTopics[post_id];
  const upvote_icon = $('#upvote-icon-' + post_id);
  if (upvotes && (Object.keys(upvotes).length > 0)) {
    // we have upvotes
    upvote_icon.removeClass('upvote mdi-arrow-up-bold-outline');
    upvote_icon.addClass('upvoted mdi-arrow-up-bold');
  } else {
    // no upvotes
    upvote_icon.removeClass('upvoted mdi-arrow-up-bold');      
    upvote_icon.addClass('upvote mdi-arrow-up-bold-outline');
  }
}

export function setupVoteModal() {
  const modal = $("#voteModal");
  var _post_id = null;

  modal.on("show.bs.modal", (event: JQueryEventObject) => {
    const button = $(event.relatedTarget);
    const parentPostID = button.data("postid");
    _post_id = parentPostID;

    // Populate the vote-buttons tag in the modal with html corresponding to all votable topics.
    overwrite_vote_buttons(parentPostID);

    // populate the modal with necessary post content
    const parentPost = $("#post-" + parentPostID);
    const parentPostContents = parentPost.html();
    const askedBy = $("#post-" + parentPostID + "-asked");
    const askedByContents = askedBy.html();
    const vote_parent_post = modal.find("#vote_parent_post");
    vote_parent_post.html(parentPostContents + askedByContents);

    modal.find("#vote_post_id").val(parentPostID);

    // delete a <br> tag
    vote_parent_post.find('#break').remove();
    // add a <br> tag before
    // vote_parent_post.find('#pre-break').html('<br>');
    // loop over tags in modal replicated post and delete
    vote_parent_post.find('span.badge').each(function() {
      $(this).remove();
    });

    setTimeout(() => {
      modal.find("#id_text").focus();
    }, 500);

    const vote_message = $('#vote-message');
    const vote_tags = vote_message.find('.select2-selection__rendered').children();
    const tag1 = vote_tags.eq(1);
    tag1.css("font-color", "yellow");
  
    
  });
  modal.on("hidden.bs.modal", () => {
    modal.find("#vote_parent_post").html("");
    modal.find("#vote_post_id").val("");
    // modal.find("#vote_buttons").html(""); // TODO: this
  });

  $('#vote-buttons').on('click', '.vote-button', function(event) {
    event.preventDefault();
    var vote_button = $(event.target);
    if (! vote_button.hasClass('btn')) {
      vote_button = vote_button.parent();
    }
    const upvoted = vote_button.data('upvoted');
    const topic_id = vote_button.data('topic-id');
    const post_id = vote_button.data('post-id');
    var direction = 0
    if (upvoted==1) {
      vote_button.removeClass('btn-success');
      vote_button.addClass('btn-outline-success');
      vote_button.data('upvoted', 0);
      HodlPalValues.removeUpvotedTopic(post_id, topic_id);
    } else {
      vote_button.removeClass('btn-outline-success');
      vote_button.addClass('btn-success');
      vote_button.data('upvoted', 1);
      HodlPalValues.addUpvotedTopic(post_id, topic_id);
      direction = 1
    }
    
    // clear all selections
    if (window.getSelection) {
      if (window.getSelection().empty) {  // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {  // Firefox
        window.getSelection().removeAllRanges();
      }
    } else if (document.selection) {  // IE?
      document.selection.empty();
    }

    // update the upvote arrow icon on main screen
    update_upvote_arrow(post_id);

    ajax_request('/vote-on-topic/', 
      { 
        "csrfmiddlewaretoken": getCookie('csrftoken'),
        "post_id": post_id,
        "topic_id": topic_id,
        "direction": direction
      },
      function(result){
      }
    );

  });

  var suggested_vote_topics = [];

  // Returns a boolean that is True if item added. False if removed.
  // Also returns the item that was either added or removed.
  // modifies suggested_vote_topics to keep track of previously entered topics.
  function get_changed_topic(vote_topics) {
    const added_topics = vote_topics.filter(x => !suggested_vote_topics.includes(x));
    const removed_topics = suggested_vote_topics.filter(x => !vote_topics.includes(x));
    suggested_vote_topics = vote_topics;
    if (added_topics.length>=1) {
      return [true, added_topics[0]];
    } else {
      return [false, removed_topics[0]];
    }
  }

  // returns an array of the all the topics in the select2 container.
  function get_vote_topics() {
    // loop over tags in parent and assign as initial in select2.
    var topics_array = [];
    $("#vote-message").find('li.select2-selection__choice').each(function() {
      const topic_name = $(this).attr("title");
      topics_array.push(topic_name);
    });
    return topics_array;  
  }

  $(document).on("change", "#id_vote_topics", function() {
    const topics_array = get_vote_topics();
    const [added, changed_topic] = get_changed_topic(topics_array);
    console.log("starting upvote")
    if (added) {
      console.log("added.")
      ajax_request('/vote-on-named-topic/', 
        {
          "csrfmiddlewaretoken": getCookie('csrftoken'),
          "post_id": _post_id,
          "topic": changed_topic,
          "direction": 1
        },
        function(response){
          console.log("finishing upvote")
          const topic_id = response['id'];
          const topicPair = [topic_id, changed_topic];
          append_voted_button(_post_id, topicPair);
          HodlPalValues.addTopicAndUpvote(_post_id, topicPair);
          // empty the box that was used to select the new topic.
          const vote_topics = $('#id_vote_topics');
          vote_topics.empty();
          update_upvote_arrow(_post_id)
          console.log("finished upvote")
          suggested_vote_topics = [];
          return;
        }
      );
    } 
  })

  /*
    function getCookie(name) {
      var cookieValue = null;
      if (document.cookie && document.cookie != '') {
          var cookies = document.cookie.split(';');
          for (var i = 0; i < cookies.length; i++) {
              var cookie = jQuery.trim(cookies[i]);
              // Does this cookie string begin with the name we want?
              if (cookie.substring(0, name.length + 1) == (name + '=')) {
                  cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                  break;
              }
          }
      }
      return cookieValue;
  }

  function update_follow_modal(username, redirect_url, ajax_url, dest_id)
  {
    $.ajax({
      type: "POST",
      beforeSend: function(request) {
        request.setRequestHeader("X-CSRF-Token", getCookie('csrftoken'));
      },        
      url: ajax_url + "/" + username + "/",
      dataType: 'html',
      data: { 
        "csrfmiddlewaretoken": getCookie('csrftoken'),
        "redirect_url": redirect_url
      },
      success: function(result)
      {
        // The div contains now the updated form 
        $('#' + dest_id).html(result);
      }
    });
    
  }
  */


}
