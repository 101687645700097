import { renderTopicsInNav } from "../main";

let descendantsRendered = false; // keeps track of whether descendants have been rendered (debouncing)
// the following variable will be used to enable dynamic rendering of ancestors.
let ancestorID: string | number;
let ancestorChildID: string | null; // this will keep track of a node's position in the vertical direction
let ancestorGrandchildID: string | null;
// we need to track an element for scroll-down separately
let popAncestorEnabled: boolean = false;
// we use this element to disable vertical tracking when we reach the last element
// let pushAncestorEnabled = false; // starts out false -- TODO: remove all pushAncestorEnabled references

export function setupPrototype(navTree: { [x: string]: any; }, rootID: string | number) {

  let crossedBelow = false;
  let crossedAbove = false;
  let pushThresholdPos: number;
  let popThresholdPos: number;
  const pushThresholdOffset = 400;
  const popThresholdOffset = 400;

  const childXPositions: any[] | never[] = [];
  $(document).ready(
    function() {
      // populate the spatial discussion tree with html of each post
      initNavTree();
      // and construct the nav tree in the GUI
      renderChildren(rootID);
      // Now, identify the ancestorChildID as the first child of the root.
      // Note, the rootID has been specified in the prototype.html django
      // template.
      ancestorChildID = getChildID(rootID, 0);
      // Similarly set initial value of ancestor grandchild if the child exists
      if (ancestorChildID) {
        ancestorGrandchildID = getChildID(ancestorChildID, 0);
      }
      // The topics shown in the top nav are determined by the current root node
      // and its selected child.
      renderTopicsInNav(rootID, ancestorChildID);
      // This is tracked as the user navigates.
      ancestorID = rootID;
    },
  );

  // initNavTree
  //
  // This function is used to locate the HTML associated with each post in the
  // navTree and store that HTML in the navTree dict. Note, this HTML has been
  // constructed already in the Django template.
  // This function is only run once, to get the initial HTML.
  function initNavTree() {
    for (const nodeID in navTree) {
      const elt = $("#pbox-" + nodeID);
      navTree[nodeID].html = elt.html();
      elt.replaceWith("");
    }
  }

  // Helper to render children.
  // This function replaces the div that has id="renderNodeRight" with a div
  // containing the HTML contents of the child identified by childID and then
  // returns the child, which will either be a Node (if rendered) or undefined.
  function renderNodeRight(childID: string) {
    const child = navTree[childID];
    if (child !== undefined) {
      let newHtml = '<li id="p' + childID + '" class="item"><div class="w-100">' + child.html;
      newHtml = newHtml + '<div id="f' + childID + '" class="d-none"></div>';
      newHtml = newHtml + '</div></li><div id="scrollPostRight"></div>';
      $("#scrollPostRight").replaceWith(newHtml);
    }
    return child;

  }

  // Helper to render an only child
  function renderNodeRightStatic(childID: string) {
    const child = navTree[childID];
    let newHtml = '<li id="p' + childID + '" class="item"><div style="width: 80%;">' + child.html;
    newHtml = newHtml + '<div id="f' + childID + '" class="d-none"></div>';
    newHtml = newHtml + '</div></li><div id="scrollPostRight" class="d-none"></div>';
    $("#scrollPostRight").replaceWith(newHtml);
  }

  // renderChildren
  // This will render the children of the currently selected nodeID, aka ancestorID.
  // The selection of nodeID is relevant, because it identifies which node is
  // being displayed as the "root" in the GUI, beneath which we are able to
  // swipe right/left. When children are rendered beneath the node with this
  // ancestorID (i.e. beneath the ancestorNode), each child's descendants are
  // subsequently rendered down to the point of a leaf node using the
  // renderDescendants function.
  
  function renderChildren(nodeID: string | number) {
    //console.log("Rendering children of " + nodeID)
    ancestorID = nodeID;
    childScrollDetectionEnabled = false;
    let childID = null;
    // child index is not yet used, but will be to move the viewport after rendering.
    let ancestorNode = navTree[nodeID];
    const children = ancestorNode.below;
    // if the ancestorNode has any children
    if (children !== undefined) {
      // if ancestorNode has more than 1 child
      if (children.length > 1) {
        // render them in a manner that is horizontally scrollable.
        // First, making this extra div (scrollPostLeft) displayed enables the
        // set of divs to be horizontally scrollable to the left.
        $("#scrollPostLeft").replaceWith('<div id="scrollPostLeft"></div>'); // remove display none
        // Iterate through each child of the ancestorNode.
        for (let renderingIndex = 0; renderingIndex < children.length; renderingIndex++) {
          childID = children[renderingIndex]; // select the child
          // render the child and confirm it exists.
          const childNode = renderNodeRight(childID);
          // If the childNode exists,
          if (childNode != undefined) {
            // then render the child's descendants.
            renderDescendants(childID, true); 
          }
        }
        // Move the viewport to the anchor tag specified by the childIndex
        const childIndex = ancestorNode.i;
        ancestorChildID = ancestorNode.below[childIndex];
        const targetChildID = children[childIndex];
        const targetChildElt = $("#post-" + targetChildID);
        if (targetChildElt != null) {
          const offset = targetChildElt.offset();
          if (offset != null) {
            const targetChildX = offset.left;
            const scrollLeft = targetChildX;
            $("#children").scrollLeft(scrollLeft);
            // now include an event to handle horizontal scrolling
            $("#children").on("scroll", handleChildScroll);
          }
        }

        // offset will be distance halfway between two adjacent elements.
        const child1 = $("#post-" + children[0]);
        const child2 = $("#post-" + children[1]);
        if (child1 != null && child2 != null) {
          const offset1 = child1.offset();
          const offset2 = child2.offset();
          if (offset1 != null && offset2 != null) {
            const childElt0X = offset1.left;
            const childElt1X = offset2.left;
            const childXOffset = (childElt1X - childElt0X) * 0.5;
            for (let i = 0; i < children.length; i++) {
              childID = children[i];
              const childElt = $("#post-" + childID);
              if (childElt != null) {
                const childOffset = childElt.offset();
                if (childOffset != null) {
                  childXPositions[i] = (childOffset.left - childElt0X) + childXOffset;
                }
              }
            }
          }
        }
        $('#children').addClass('scroll');
      } else if (children.length === 1) {
        // console.log("one child")
        // render the child node without performing any scrolling
        $("#scrollPostLeft").replaceWith('<div id="scrollPostLeft" class="d-none"></div>');
        childID = children[0];
        // this handles centering the only child and causing empty space on right to be display none.
        renderNodeRightStatic(childID);// renderNodeRightStatic(childID);
        const children_div = $("#children");
        children_div.css("grid-auto-columns", "100%");
        ancestorNode = navTree[ancestorID];
        ancestorNode.i = 0;
        ancestorChildID = ancestorNode.below[ancestorNode.i];
        renderDescendants(childID, true);
        children_div.removeClass('scroll');
      }
      // Update topics in nav
      renderTopicsInNav(ancestorID, ancestorChildID);
      childScrollDetectionEnabled = true;
    }
  }

  // This will clear html of all children and their descendants
  function clearChildren() {
    const elt = $("#children");
    elt.replaceWith('<ul id="children" class="scroll"><div id="scrollPostLeft"></div><div id="scrollPostRight"></div></ul>');
    descendantsRendered = false;
  }

  // Helper to render a child. Renders it and returns the child's node ID.
  // The reason we need the footID is simply to track the HTML ID of the placeholder footer div.
  // INPUT
  // parentID:  the ID of the parent, below which a child shall be rendered.
  // footID:    the ID of the footer beneath the parent, to tell where to place the child.
  // renderOn:  a boolean stating whether to perform the render. if this is false, then
  //            no rendering is performed, but the function can still return childNodeID
  // OUTPUT
  // childNodeID: this is the ID of the child node if it exists or null otherwise.
  //
  // SIDE EFFECTS
  // This function renders HTML corresponding to a child node in the location of the
  // div identified by footID, if the selected parentID has a child.
  function renderNodeBelow(parentID: string | number, footID: string, renderOn: boolean) {
    const node = navTree[parentID];
    const childNodes = node.below;
    if (childNodes) {
      const childNodeID = childNodes[node.i];
      if (childNodeID) {
        const childNode = navTree[childNodeID];
        if (childNode) {
          if (renderOn) {
            const childHtml = childNode.html;
            const nodeFootElement = $("#f" + footID);
            nodeFootElement.replaceWith(childHtml + '<div id="f' + footID + '" class="d-none"></div>');
          }
          return childNodeID;
        }
      }
    }
    return false;

  }

  // This will render descendants of a node, starting at the child identified
  // by the nodeID and going down a branch along each currently selected child node
  // of each succesive child node. Note, the currently selected node of each child
  // in this chain is identified by the "i" element in the navTree data structure.
  // When the navTree is initialized, it starts with all elements as i=0 currently.
  // INPUTS:
  //
  // nodeID: identifies the node for which to render descendants.
  // renderOn: specifies whether rendering if enabled. If false, then nothing will
  // be rendered, but the function will update threshold positions appropriately.
  function renderDescendants(nodeID: string, renderOn: boolean) {
    const node = navTree[nodeID];
    //console.log("Rendering descendants of " + nodeID)
    if (node) {
      //console.log("Node exists");
      // enable popping if we have not reached the root. might need to move this code.
      let parentID = node.above;
      popAncestorEnabled = (parentID !== rootID);
      ancestorChildID = nodeID;
      if (popAncestorEnabled) { // handle popping of ancestor
        const ancestorChildElt = $("#post-" + ancestorChildID);
        const ancestorChildOffset = ancestorChildElt.offset()
        if (ancestorChildOffset != null) {
          popThresholdPos = ancestorChildOffset.top - popThresholdOffset;
        }
      }
      const children = node.below;
      // only take action if this element has children
      if (children.length > 0) {
        //console.log("Node has children")
        if (renderOn) {
          // first, clear the cancellation element if it exists
          const startingElt = $("#c" + nodeID);
          // create a cancellable element
          startingElt.replaceWith('<div id="c' + nodeID + '"><div id="f' + nodeID + '" class="d-none"></div></div>');
        }
        parentID = nodeID;
        // firstElt will enable us to track vertical position of the first child
        let firstElt = true;
        ancestorGrandchildID = null;

        let newParentID: string;

        while (parentID) {
          // note, newParentID contains the ID of the node below the node given by parentID.
          // if there are no nodes below the parentID node, it can't render a node below and returns false
          newParentID = renderNodeBelow(parentID, nodeID, renderOn);
          // console.log("Parent ID: " + parentID + ", newParentID: " + newParentID);
          // console.log("new parent ID: " + newParentID);
          descendantsRendered = true;
          // if this is the first element and the ancestor grandchild was successfully rendered
          if (firstElt && newParentID) {
            // this means can track both the child and grandchild of the ancestor for pop and push respectively
            // update push event tracking variables
            ancestorGrandchildID = newParentID;
            const ancestorGrandchildElt = $("#post-" + ancestorGrandchildID);
            if (ancestorGrandchildElt != null) {
              const ancestorGrandchildOffset = ancestorGrandchildElt.offset();
              if (ancestorGrandchildOffset != null) {
                pushThresholdPos = ancestorGrandchildOffset.top - pushThresholdOffset;
                // pushAncestorEnabled = true;
              }
            }
            // if this is the first element but we could not render the ancestor grandchild
          } else if (firstElt) {
            // we will only track the ancestor child for popping and have no more elements to push
            ancestorGrandchildID = null;
            // pushAncestorEnabled = false;
            // console.log("pushing the very last element");
          }
          firstElt = false;
          parentID = newParentID;
        }
      } else {
        // there are no children to push. note it.
        ancestorChildID = nodeID;
        ancestorGrandchildID = null;
        // pushAncestorEnabled = false;
      }
    }
  }


  // get the child ID, protecting against the case that it doesn't exist
  function getChildID(nodeID: string | number, childIndex: number): string | null {
    const parent = navTree[nodeID];
    const children = parent.below;
    let childID: string | null = null;
    if (children) {
      childID = children[childIndex];
      return childID;
    }
    return childID;
  }


  $(window).scroll(function() {
    // console.log("Scrolling with position: " + $(this).scrollTop() + ", push: " + pushThresholdPos + ", enabled: " + pushAncestorEnabled + " pop:" + popThresholdPos + ", enabled: " + popAncestorEnabled);
    if (ancestorGrandchildID) {
      if ($(this).scrollTop() > pushThresholdPos && !crossedBelow) { // (pushAncestorEnabled && ($(this).scrollTop() > pushThresholdPos && !crossedBelow)) {
        // cross below
        pushAncestor();
      }
    }
    if (popAncestorEnabled) {
      if (popAncestorEnabled && ($(this).scrollTop() < popThresholdPos && !crossedAbove)) {
        // cross above
        popAncestor();
      }
    }
  });

  let detectingRightThreshold = false;
  let detectingLeftThreshold = false;
  let leftThreshold = null;
  let rightThreshold = null;
  let childScrollDetectionEnabled = false;

  function handleChildScroll() {

    if (childScrollDetectionEnabled && (childXPositions.length > 1)) {
      const scrollLeft = $(this).scrollLeft();
      const ancestorNode = navTree[ancestorID];
      if (ancestorNode.i === 0) {
        rightThreshold = childXPositions[1];
        detectingRightThreshold = true;
        detectingLeftThreshold = false;
      } else if (ancestorNode.i === (childXPositions.length - 1)) {
        leftThreshold = childXPositions[childXPositions.length - 1];
        detectingRightThreshold = false;
        detectingLeftThreshold = true;
      } else {
        leftThreshold = childXPositions[ancestorNode.i];
        rightThreshold = childXPositions[ancestorNode.i + 1];
        detectingRightThreshold = true;
        detectingLeftThreshold = true;
      }
      if (detectingLeftThreshold) {
        if (scrollLeft < leftThreshold) {
          ancestorNode.i--;
          ancestorChildID = getChildID(ancestorID, ancestorNode.i);
          renderTopicsInNav(ancestorID, ancestorChildID); // update dynamic display of topics in nav
          if (ancestorChildID != null) {
            const ancestorChildNode = navTree[ancestorChildID];
            if (ancestorChildNode != null) {
              ancestorGrandchildID = getChildID(ancestorChildID, ancestorChildNode.i);
            }
          }
        }
      }
      if (detectingRightThreshold) {
        if (scrollLeft >= rightThreshold) {
          ancestorNode.i++;
          ancestorChildID = getChildID(ancestorID, ancestorNode.i);
          renderTopicsInNav(ancestorID, ancestorChildID); // update dynamic display of topics in nav
          if (ancestorChildID != null) {
            const ancestorChildNode = navTree[ancestorChildID];
            if (ancestorChildNode != null) {
              ancestorGrandchildID = getChildID(ancestorChildID, ancestorChildNode.i);
            }
          }
        }
      }
    }

  }

  function pushAncestor() {
    // console.log("pushing ancestor" + ancestorChildID);
    crossedBelow = true;
    crossedAbove = true;
    const ancestorNode = navTree[ancestorChildID];
    if (ancestorNode && (ancestorNode.below.length > 0)) {
      // add new html to empty ancestor location
      const ancestorHtml = ancestorNode.html;
      const elt = $("#emptyAncestor");
      elt.replaceWith(
        '<div id="a'
        + ancestorChildID
        + '" class="w-100 text-center"><div class="d-inline-block text-left" style="width: 90%;">'
        + ancestorHtml
        + "</div></div>"
        + '<div id="emptyAncestor" class="d-none"></div>',
      );
      // keep track of the previous ancestor node
      const ancestorNodeID = ancestorChildID;
      // queue up the next ancestor in waiting
      ancestorChildID = getChildID(ancestorNodeID, ancestorNode.i);
      // erase children
      clearChildren();
      // render new children
      popAncestorEnabled = true;
      if (ancestorNode.below.length > 0) {
        renderChildren(ancestorNodeID);
        // call renderDescendants with renderOn=false to reset vertical tracking
        renderDescendants(ancestorChildID, false);
      }
    }
    crossedBelow = false; // using this var to lock access in case still firing
    crossedAbove = false;
  }

  function popAncestor() {
    // console.log("popping ancestor");
    crossedAbove = true;
    crossedBelow = true;
    const centerNode = navTree[ancestorChildID];
    if (centerNode) {
      const ancestorNodeID = centerNode.above;
      if (ancestorNodeID) {
        // add new html to empty ancestor location
        const ancestorNode = navTree[ancestorNodeID];
        const elt = $("#a" + ancestorNodeID);
        elt.replaceWith('<div id="emptyAncestor" class="d-none"></div>');
        // check whether above is root.
        const newAncestorNodeID = ancestorNode.above;
        const newAncestorNode = navTree[newAncestorNodeID];
        // [# popAncestorEnabled = (newAncestorNodeID != rootID); -- handled by renderDescendants.
        // erase children
        clearChildren();
        // render new children
        const childNodes = newAncestorNode.below;
        // also found parasitic scope problem below requiring me to prepend childNodeID with a _
        const _childNodeID = childNodes[newAncestorNode.i]; // note should be same as ancestorGrandchildID
        renderChildren(newAncestorNodeID);
        // call renderDescendants with renderOn=false to reset vertical tracking
        renderDescendants(_childNodeID, false);
      }
      crossedAbove = false; // using this var to lock access in case still firing
      crossedBelow = false;
    }
  }

  //
  // NOTE:: CODE FOR HANDLING TOPICS DIPSLAYED ON THIS PAGE IS LOADED IN _topics_bar_js.html by _base.html.
  //
}
