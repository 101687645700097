export function setupReplyModal() {
  const modal = $("#replyModal");

  function showOneTabAndPane(shownTab, shownPane, hiddenTab, hiddenPane) {
    shownTab.removeClass("invisible");
    shownTab.find("a").addClass("active show");
    shownPane.removeClass("invisible");
    shownPane.addClass("active show");
    hiddenTab.addClass("invisible");
    hiddenTab.find("a").removeClass("active show");
    hiddenPane.addClass("invisible");
    hiddenPane.removeClass("active show");
  }

  modal.on("show.bs.modal", (event: JQueryEventObject) => {
    const button = $(event.relatedTarget);
    const parentPostID = button.data("postid");
    
    const rootQuestion = button.data("rootq");
    const exp_tab = modal.find("#experience-tab");
    const rep_tab = modal.find("#reply-tab");
    const exp_pane = modal.find("#post_an_experience");
    const rep_pane = modal.find("#post_a_reply");
    console.log("Setting up.")
    if (rootQuestion) { // is the root question. share experience only.
      showOneTabAndPane(exp_tab, exp_pane, rep_tab, rep_pane);
    } else { // is not the root question. reply only.
      showOneTabAndPane(rep_tab, rep_pane, exp_tab, exp_pane);
    }

    // populate the modal with necessary post content
    const parentPost = $("#post-" + parentPostID);
    const parentPostContents = parentPost.html();
    const askedBy = $("#post-" + parentPostID + "-asked");
    const askedByContents = askedBy.html();
    const reply_parent_post = modal.find("#reply_parent_post");
    reply_parent_post.html(parentPostContents + askedByContents);
    reply_parent_post.find('.line-clamp-3').removeClass('line-clamp-3')
    reply_parent_post.find('.line-clamp-4').removeClass('line-clamp-4')


    // modal.find("#vote_parent_post_id").val(parentPostID);
    // delete a <br> tag
    reply_parent_post.find('#break').remove();
    // add a <br> tag before
    // reply_parent_post.find('#pre-break').html('<br>');
    // loop over tags in modal replicated post and delete
    reply_parent_post.find('span.badge').each(function() {
      $(this).remove();
    });
    
    modal.find("#reply_parent_post_id").val(parentPostID);
    modal.find("#experience_parent_post_id").val(parentPostID);
    setTimeout(() => {
      modal.find("#id_text").focus();
    }, 500);

    const reply_topics = $('#id_reply_topics');
    const exp_topics = $('#id_experience_topics');
    
    // loop over tags in parent and assign as initial in select2.
    var vote_array = [];
    var data = parentPost.find('span.badge').each(function() {
      const topic_id = $(this).data('id');
      vote_array.push(topic_id);
    });
    if (vote_array.length > 0) {
      reply_topics.empty();
      exp_topics.empty();
      var data = parentPost.find('span.badge').each(function() {
        const topic_name = $(this).html();
        const topic_id = $(this).data('id');
        reply_topics.append(
          $('<option/>').val(topic_id).text(topic_name)
        );
        exp_topics.append(
          $('<option/>').val(topic_id).text(topic_name)
        );
      });      
      reply_topics.val(vote_array).trigger('change')
      exp_topics.val(vote_array).trigger('change')
    }

  });

  modal.on("hidden.bs.modal", () => {
    modal.find("#reply_parent_post").html("");
    modal.find("#reply_parent_post_id").val("");
    modal.find("#experience_parent_post").html("");
    modal.find("#experience_parent_post_id").val("");
    var vote_array = [];
    const reply_topics = $('#id_reply_topics');
    const exp_topics = $('#id_experience_topics');
    reply_topics.empty();
    exp_topics.empty();
    reply_topics.val(vote_array).trigger('change')
    exp_topics.val(vote_array).trigger('change')
  });
}
