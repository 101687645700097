import '../scss/main.scss'
import { GoogleAnalyticsEvents } from "./analytics/GoogleAnalyticsEvents"
// import 'bootstrap'
export { setupReplyModal } from "./setupReplyModal"
export { setupVoteModal } from "./setupVoteModal"
export { setupPrototype } from "./prototype/prototype"

export const HodlPalValues = {
  postTopics: [],
  postUpvotedTopics: {},
  addUpvotedTopic: null,
  removeUpvotedTopic: null,
  addTopicAndUpvote: null,
  selectedTopics: [],
  disabledTopics: []
}

export * from "./topics_bar/topics_bar";
export { GoogleAnalyticsEvents } from "./analytics/GoogleAnalyticsEvents";

export class HodlpalMain {
  
}

