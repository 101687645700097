import { HodlPalValues } from "../main";

export function renderAllTopicsInNav() {

  var topicsList = [];
  for (const [_postID, _postTopicList] of Object.entries(HodlPalValues.postTopics)) {
    appendTopicsList(topicsList, _postTopicList);
    // {# console.log(topicsList);
  }
  // {# console.log("topicsList: " + topicsList);
  var topicsHtml = topicsListToHtml(topicsList);
  // {# console.log("topicsHtml: " + topicsHtml);
  var topicsNavElt = $('#topics-list');
  topicsNavElt.replaceWith(topicsHtml);

}

// {# This function assumes that a child node is visible on the screen and
// {# navigates the ancestors in the navTree to display associated topics
// {# on-screen.
export function renderTopicsInNav(parentNodeID: string | number, childNodeID: string | null) {

  // {# First, get the list of topics to display
  var topicsList = getTopicsList(parentNodeID, childNodeID);
  var topicsHtml = topicsListToHtml(topicsList)
  var topicsNavElt = $('#topics-list');
  topicsNavElt.replaceWith(topicsHtml);  
}

// {# This adds only unique topics from newTopicsList to baseTopicsList
export function appendTopicsList(baseTopicsList, newTopicsList) {
  
  if (baseTopicsList == undefined) {
    baseTopicsList = newTopicsList;
  } else if (newTopicsList != undefined) {
    for (var i=0; i<newTopicsList.length; i++) {
      var _elementOccurred = false;
      for (var j=0; j<baseTopicsList.length; j++) {
        if (baseTopicsList[j][0] == newTopicsList[i][0]) {
          _elementOccurred = true;
        }
      }
      if (!(_elementOccurred) || (baseTopicsList.length==0)) {
        baseTopicsList.push(newTopicsList[i]);
        // {# console.log("New topic " + newTopicsList[i])
      }
    }
  }

}

export function getTopicsList(parentNodeID, nodeID) {
  
  const topicsList = []
  appendTopicsList(topicsList, HodlPalValues.postTopics[parentNodeID]);
  appendTopicsList(topicsList, HodlPalValues.postTopics[nodeID]);
  return topicsList;
}

export function topicsListToHtml(topicsList) {
  var nav_group_name = $('#nav-group-name');
  var nav_topic_clear = $('#nav-topic-clear');
  // {# console.log("nav_group_name" + nav_group_name) #}
  // {# console.log("Length: " + nav_group_name.length) #}
  var nav_group_content;
  if (nav_group_name.length) {
    // {# console.log("found nav_group_name") #}
    nav_group_content = '<li id="nav-group-name">' + nav_group_name.html() + '</li>';
  } else {
    // {# console.log("did not find nav_group_name") #}
    nav_group_content = "";
  }
  if (nav_topic_clear.length) {
    nav_group_content = nav_group_content + '<li id="nav-topic-clear">' + nav_topic_clear.html() + '</li>';
  } 
  // {# console.log("nav_group_content is" + nav_group_content) #}
  var topicsHtml = '<ul id="topics-list" class="nav-topics-scroll">' + nav_group_content + '<li id="topics-start" class="d-none"></li>';
  if (topicsList != undefined) {
    for (var i=0; i<topicsList.length; i++) {
      var topic = topicsList[i];
      var topicID = topic[0];
      var topicName = topic[1];
      var badge_style = "secondary";
      if (HodlPalValues.selectedTopics.indexOf(topicID) !== -1) {
        badge_style = "success";
      }
      topicsHtml += '<li class="nav-topic" data-tID="' + topicID + '"><a href="/topic-toggle/' + topicID + '"><button class="btn btn-' + badge_style + ' btn-thin btn-narrow"><strong>' + topicName + '</strong></button></a></li>';
    }
  }
  if (HodlPalValues.disabledTopics != undefined) {
    for (var i=0; i<HodlPalValues.disabledTopics.length; i++) {
      var topic = topicsList[i];
      var topicID = topic[0];
      var topicName = topic[1];
      var badge_style = "secondary";
      if (HodlPalValues.selectedTopics.indexOf(topicID) !== -1) {
        badge_style = "success";
      }
      topicsHtml += '<li class="nav-topic" data-tID="' + topicID + '"><a href="/topic-toggle/' + topicID + '"><span class="badge badge-' + badge_style + ' topic-badge">' + topicName + '</span></a></li>';
    }
  }

  topicsHtml += '<li id="topics-end" class="d-none"></li></ul>';
  return topicsHtml;
}
